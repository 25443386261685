import 'slick-carousel';
import '@slick/slick.css';

export default class SlickSlider {
  constructor(elem) {
    this.slideOption = {
      dots:          true,
      arrows:        true,
      speed:         1000,
      slidesToShow:   1,
      slidesToScroll: 1,
      autoplay:      false,
      autoplaySpeed: 3000,
      infinite:      true,
    }
    this.slide(elem);
  }

  slide(elem) {
    const slider = $(elem);
    slider.slick(this.slideOption);
  }

  unslide(elem) {
    const slider = $(elem);
    slider.slick('unslick');
  }
}
