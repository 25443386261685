export default () => {
  const checkElem = document.querySelector('.c-form__privacy input');
  const submitElem = document.querySelector('.c-form__submitButton button');
  if(checkElem) {
    checkElem.addEventListener('click', (e) => {
      if(e.target.checked === true) {
        submitElem.disabled = false;
      } else {
        submitElem.disabled = true;
      }
    })
  }
}

