export default() => {
  // 更新情報が5件以上の処理
  const newsItem = document.querySelectorAll('.partner-news .c-info__bodyInner a');
  const newsItemCount = 3;
  if(newsItem.length && newsItem.length > newsItemCount) {
    for(let i = newsItemCount; i < newsItem.length; i++) {
      newsItem[i].style.display = "none";
    }
    // もっと見るボタン
    const moreBtn = document.querySelector('.partner-news__more button');
    moreBtn.addEventListener('click', (e) => {
      if(moreBtn.classList.contains('is-open')) {
        for(let i = newsItemCount; i < newsItem.length; i++) {
          newsItem[i].style.display = "none";
        }
        moreBtn.classList.remove('is-open');
        moreBtn.querySelector('span').innerHTML = "もっと見る";
      } else {
        for(let i = newsItemCount; i < newsItem.length; i++) {
          newsItem[i].style.display = "block";
        }
        moreBtn.classList.add('is-open');
        moreBtn.querySelector('span').innerHTML = "閉じる";
      }
    });
  }
}
