export default () => {
  const searchBtn = document.querySelector('.l-header__searchButton');
  const searchElem = document.querySelector('.l-header__search');
  const searchInputText = document.querySelector('.l-header__searchInputText');
  const searchCloseBtn = document.querySelector('.l-header__searchCloseButton');
  if(searchBtn) {
    searchBtn.addEventListener('click', () => {
      if(searchElem.classList.contains('is-open')) {
        searchElem.classList.remove('is-open');
      } else {
        searchElem.classList.add('is-open');
        setTimeout(() => {
          searchInputText.focus();  
        }, 300);
        
      }
    });
    searchCloseBtn.addEventListener('click', () => {
      searchElem.classList.remove('is-open');
    });
  }
}

