export default() => {
  const tabBtns = document.querySelectorAll('.c-tab__btn');
  const tabConts = document.querySelectorAll('.c-tab__content');

  for (let i = 0; i < tabBtns.length; i++) {
    let tgt = tabBtns[i];
    tgt.addEventListener('click', (e)=> {
      e.preventDefault();
      const btnData = tgt.getAttribute('data-tab');
      tabChange(btnData,tgt);
    })
  }

  const tabChange = (btnData, tgt) => {
    for (let i = 0; i < tabConts.length; i++) {
      const tabCont = tabConts[i];
      const areaCat = tabCont.getAttribute('id');
      const selectArea = document.getElementById(areaCat);

      if(btnData == areaCat){
        tgt.classList.add('is-active');
        selectArea.classList.add('is-open');
      }
      else{
        tabBtns[i].classList.remove('is-active');
        selectArea.classList.remove('is-open');
      }
    }
  }

}
