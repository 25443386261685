export default () => {
  const sideNavElem = document.querySelector('.c-side__nav');
  const sideNavLinks = document.querySelectorAll('.c-side__navLink');
  const sideNavChildLinks = document.querySelectorAll('.c-side__navChildLink');

  if(sideNavElem) {
    let loc = location.pathname;
    const pageDir = loc.substring(0, loc.lastIndexOf('/')) + '/';
    for(let i = 0; i < sideNavLinks.length; i++) {
      const href = sideNavLinks[i].getAttribute('href');
      if(pageDir === href) {
        sideNavLinks[i].parentNode.classList.add("is-current");
      }
    }
    for(let i = 0; i < sideNavChildLinks.length; i++) {
      const href = sideNavChildLinks[i].getAttribute('href');
      if(pageDir === href) {
        sideNavChildLinks[i].parentNode.classList.add("is-current");
        sideNavChildLinks[i].closest('.has-child').classList.add("is-open");
      }
    }
  }
}