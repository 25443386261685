import SlickSlider from '../common/SlickSlider';
import { bp } from '../common/BreakPoint';

export default class TopAppealSlider extends SlickSlider {
  constructor(elem) {
    super();
    this.slideOption = {
      dots:          true,
      slidesToShow:   1,
      slidesToScroll: 1,
      prevArrow:     '<div class="slick-prev slick-arrow">',
      nextArrow:     '<div class="slick-next slick-arrow">',
      centerMode: true,
      centerPadding: '47px',
    };
    this.slideFlag;
    const bpCheck = (bp) => {
      if (bp.matches) {
        if (this.slideFlag) {
          this.unslide(elem);
          this.slideFlag = false;
        }
      } else {
        this.slide(elem);
        this.slideFlag = true;
      }
    };
    bp.addEventListener("change", bpCheck);
    bpCheck(bp);
  }
}
 
