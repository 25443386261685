export default () => {
  const elem = document.querySelectorAll('.selectbox__anchor');
  if(elem.length) {
    for(let i = 0; i < elem.length; i++) {
      elem[i].addEventListener('change', () => {
        const val = elem[i].value;
        const targetElem = document.getElementById(val.replace('#', ''));
        const margin = 92;
        const rect = targetElem.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const target = rect + offset - margin;
        window.scroll({
          top: target,
          behavior: 'smooth'
        });
      })
    }
  }
}
