import { bp } from '../common/BreakPoint';
export default () => {
  // humberger
  const navHasChildElems = document.querySelectorAll('.l-footer__navLink');

  const bpCheck = (bp) => {
    if (bp.matches) {
      navHasChildElems.forEach((navHasChildElem) => {
        navHasChildElem.classList.remove('is-open');
      });
    }
  };
  bp.addEventListener("change", bpCheck);
  bpCheck(bp);
  
  navHasChildElems.forEach((navHasChildElem) => {
    navHasChildElem.addEventListener('click', (e) => {
      if(e.currentTarget.classList.contains('has-child')) {
        e.currentTarget.classList.toggle('is-open');
      }
    });
  });
}

