// 既存サイトのjs
export default () => {
  // business, film 配下の既存から移行したリストで使用
  const table = document.querySelectorAll('.es-capacitor__content table.table');
  if(table) {
    $("table.even tr:odd,table.odd tr:even").css({background:"#E6E7E9"});
    for(let i = 0; i < table.length; i++) {
      const tgt = table[i].querySelectorAll('tbody td');
      for(let t = 0; t < tgt.length; t++) {
        tgt[t].addEventListener('click',(e) => {
          const url = e.target.closest('tr').querySelector('td a:first-of-type').href;
          window.location = url;
        })
      }
    }
  }
}

