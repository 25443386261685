import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
export default () => {
  const hash = location.hash;
  window.addEventListener('load', () => {
    if(hash) {
      const targetElem = document.getElementById(hash.replace('#', ''));
      const margin = 100;
      const rect = targetElem.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const target = rect + offset - margin;
      window.scroll({
        top: target,
      });
    }
  });
  

  const links = document.querySelectorAll('a[href^="#"]');
  for ( let i = 0; i < links.length; i++ ) {
    links[i].addEventListener('click', (e) => {
      e.preventDefault();
      const href = links[i].getAttribute('href');
      if(href==="#") {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        const targetElem = document.getElementById(href.replace('#', ''));
        const margin = 92;
        const rect = targetElem.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const target = rect + offset - margin;
        window.scroll({
          top: target,
          behavior: 'smooth'
        });
      }
    });
  }
}