import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { bp } from '../common/BreakPoint';
export default () => {
  const sideElem = document.querySelector('.c-side');
  const mainElem = document.getElementById('main');
  const mainPos = mainElem.getBoundingClientRect().top + window.pageYOffset;
  let clickFlag = false;
  if(sideElem) {
    const sideNavTrigger = document.querySelector('.c-side__navButton');
    sideNavTrigger.addEventListener('click', (e) => {
      sideElem.classList.toggle('is-open');
      if (sideElem.classList.contains('is-open')) {
        disableBodyScroll(sideElem);
        clickFlag = true;
      } else {
        clearAllBodyScrollLocks(sideElem);
        clickFlag = false;
      }
    });

    const sideNavChildLinks = document.querySelectorAll('.c-side__navChildLink');
    for(let i = 0; i < sideNavChildLinks.length; i++) {
      sideNavChildLinks[i].addEventListener('click', (e) => {
        clearAllBodyScrollLocks(sideElem);
        clickFlag = false;
      });
    }

    const bpCheck = (bp) => {
      if (bp.matches) {
        clearAllBodyScrollLocks(sideElem);
        sideElem.classList.remove('is-open');
      } else {
        let startPos = 0;
        let scrollFlag = false;

        window.addEventListener('load', () => {
          window.addEventListener('scroll', () => {
            if(scrollFlag && !clickFlag) {
              let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
              let currentPos = scrollTop;
              if (mainPos > currentPos || 0 > currentPos - startPos) {
                sideElem.classList.remove('is-hide');
              } else {
                sideElem.classList.add('is-hide');
              }
              startPos = currentPos;
            }
            scrollFlag = true;
          });
        });
      }
    };
    bp.addEventListener("change", bpCheck);
    bpCheck(bp);
  }
}