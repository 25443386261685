import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { bp1080 } from '../common/BreakPoint';
export default () => {
  // humberger
  const humbergerBtn = document.querySelector('.l-header__navButton');
  const navElem = document.querySelector('.l-header__nav');
  const navHasChildElems = document.querySelectorAll('.l-header__navLink');

  if(humbergerBtn) {
    const bpCheck = (bp1080) => {
      if (bp1080.matches) {
        navElem.classList.remove('is-show');
        humbergerBtn.classList.remove('is-open');
        navHasChildElems.forEach((navHasChildElem) => {
          navHasChildElem.classList.remove('is-open');
        });
        clearAllBodyScrollLocks(navElem);
      }
    };
    bp1080.addEventListener("change", bpCheck);
    bpCheck(bp1080);
    
    humbergerBtn.addEventListener('click', () => {
      if(humbergerBtn.classList.contains('is-open')) {
        humbergerBtn.classList.remove('is-open');
        navElem.classList.remove('is-show');
        navHasChildElems.forEach((navHasChildElem) => {
          navHasChildElem.classList.remove('is-open');
        });
        clearAllBodyScrollLocks(navElem);
      } else {
        humbergerBtn.classList.add('is-open');
        navElem.classList.add('is-show');
        disableBodyScroll(navElem);
      }
    });

    navHasChildElems.forEach((navHasChildElem) => {
      navHasChildElem.addEventListener('click', (e) => {
        if(e.currentTarget.classList.contains('has-child')) {
          e.currentTarget.classList.toggle('is-open');
        }
      });
    });
  }
}

