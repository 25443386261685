export default () => {
  const fadeTargets = Array.prototype.slice.call(document.getElementsByClassName('u-fade'))
  const options = {
    root: null,
    rootMargin: '-50px 0px',
    threshold: 0,
  }
  const targets = (entries) => {
    entries.forEach( entry =>{
      if(entry.isIntersecting) {
        entry.target.classList.add('is-intersecting')
      }
    });
  }
  fadeTargets.forEach(target => {
    const io = new IntersectionObserver(targets, options);
    io.observe(target)
  })
}