export default () => {
  const pageTopElem = document.getElementById('pagetop');
  const startElem = document.getElementById('header');
  const endElem = document.getElementById('footer');
  const onIntersectStart = (targetElem) => {
    if (!targetElem[0].isIntersecting) {
      pageTopElem.classList.add('is-fixed');
    } else {
      pageTopElem.classList.remove('is-fixed');
    }
  }
  const onIntersectEnd = (targetElem) => {
    if (!targetElem[0].isIntersecting) {
      pageTopElem.classList.add('is-fixed');
      pageTopElem.classList.remove('is-static');
    } else {
      pageTopElem.classList.add('is-static');
      pageTopElem.classList.remove('is-fixed');
    }
  }
  const ioStart = new IntersectionObserver(onIntersectStart);
  const ioEnd = new IntersectionObserver(onIntersectEnd);
  ioStart.observe(startElem);
  ioEnd.observe(endElem);
}
