export default() => {
  const accBtns = document.querySelectorAll('.c-acc__btn');

  for (let i = 0; i < accBtns.length; i++) {
    let tgt = accBtns[i];
    tgt.addEventListener('click', (e)=> {
      e.preventDefault();
      tgt.classList.toggle('is-open');
    })
  }
}
