import SlickSlider from '../common/SlickSlider';

export default class TopKvSlider extends SlickSlider {
  constructor(elem) {
    super();
    this.slideOption = {
      dots:          true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 800,
      slidesToShow:   1,
      slidesToScroll: 1,
      prevArrow:     '<div class="slick-prev slick-arrow">',
      nextArrow:     '<div class="slick-next slick-arrow">',
      appendDots: $('.kv__controls'),
      appendArrows: $('.kv__controls'),
    };
    this.slide(elem);
  }
  slide(elem) {
    const slider = $(elem);
    slider.on('init', function() {
      const shutter = document.querySelector('.kv__shutter');
      const sliderWrap = document.querySelector('.kv__sliderWrap');
      if(shutter) {
        shutter.classList.add('is-animated')
      }
      if(sliderWrap) {
        sliderWrap.classList.add('is-animated');
      }
    });
    slider.slick(this.slideOption);
  }
}
