import wNumb from 'wnumb';
import noUiSlider from 'nouislider';
import '@nouislider/dist/nouislider.min.css';
import MicroModal from "micromodal";
import { setContent } from "../common/Modal"

export default() => {
  // rangeスライダー
  const range = document.querySelectorAll('.c-search-products__slider');
  const inputLower = document.querySelectorAll('.c-search-products__sliderInputLower');
  const inputUpper = document.querySelectorAll('.c-search-products__sliderInputUpper');
  for(let i = 0; i < range.length; i++) {
    // nouislider作成
    noUiSlider.create(range[i], {
      start: [0, 100],
      connect: true,
      range: {
        'min': 2,
        'max': 35
      },
      pips : {
        mode : 'count',
        stepped : true,
        values : 2,
        density : -1,
      },
      format : wNumb({
        decimals : 2,
        edit : function(value) {
          return parseFloat(value);
        },
      }),
    });
    // スライダー更新時の処理（値をinputに反映）
    range[i].noUiSlider.on('update', function (values, handle) {
      const value = values[handle];
      const parentNode = this.target.parentNode;
      if(handle) {
        parentNode.getElementsByClassName('c-search-products__sliderInputUpper')[0].value = value;
      } else {
        parentNode.getElementsByClassName('c-search-products__sliderInputLower')[0].value = value;
      }
    });
    // テキストボックス更新時の処理（値をスライダーに反映）
    inputLower[i].addEventListener('change', (e) => {
      const parentNode = e.target.closest('.noUiSlider');
      parentNode.getElementsByClassName('c-search-products__slider')[0].noUiSlider.set([ e.target.value, null ]);
    });
    inputUpper[i].addEventListener('change', (e) => {
      const parentNode = e.target.closest('.noUiSlider');
      parentNode.getElementsByClassName('c-search-products__slider')[0].noUiSlider.set([ null, e.target.value ]);
    });
  }
  
  // リセットボタン処理
  const resetButton = document.querySelectorAll('.c-search-products__reset');
  for(let i = 0; i < resetButton.length; i++) {
    resetButton[i].addEventListener('click', (e) => {
      // チェックボックスfalse処理
      const parentNode = e.target.closest('.c-search-products__box');
      const checkboxList = parentNode.querySelectorAll('.c-search-products__checkbox input');
      for(let t = 0; t < checkboxList.length; t++) {
        checkboxList[t].checked = false;
      }
      // テキストボックス初期化処理
      const inputNumber = parentNode.querySelectorAll('.c-search-products__sliderInput');
      if(inputNumber.length) {
        inputNumber[0].querySelectorAll('.c-search-products__sliderInputLower')[0].value = "2";
        inputNumber[0].querySelectorAll('.c-search-products__sliderInputUpper')[0].value = "35";
      }
      // range初期化処理
      const uiSlider = parentNode.querySelectorAll('.c-search-products__slider');
      if(uiSlider.length) {
        uiSlider[0].noUiSlider.set([ 2, 35 ]);
      }
    });
  }
  
  // 全体リセットボタン処理
  const resetButtonAll = document.querySelectorAll('.c-search-products__buttonReset');
  if(resetButtonAll.length) {
    resetButtonAll[0].addEventListener('click', (e) => {
      // チェックボックスfalse処理
      const parentNode = e.target.closest('.c-search-products__inner');
      const checkboxList = parentNode.querySelectorAll('.c-search-products__checkbox input');
      for(let i = 0; i < checkboxList.length; i++) {
        checkboxList[i].checked = false;
      }
      // テキストボックス初期化処理
      const inputNumber = parentNode.querySelectorAll('.c-search-products__sliderInput');
      for(let i = 0; i < inputNumber.length; i++) {
        inputNumber[i].querySelectorAll('.c-search-products__sliderInputLower')[0].value = "2";
        inputNumber[i].querySelectorAll('.c-search-products__sliderInputUpper')[0].value = "35";
      }
      // range初期化処理
      const uiSlider = parentNode.querySelectorAll('.c-search-products__slider');
      for(let i = 0; i < uiSlider.length; i++) {
        uiSlider[i].noUiSlider.set([ 2, 35 ]);
      }
    });
  }

  // 絞り込み項目数が8件以上の処理
  const searchItem = document.querySelectorAll('.c-search-products__refineFoot .c-search-products__box');
  const moreBtn = document.querySelector('.c-search-products__more button');
  if(searchItem.length && searchItem.length > 8) {
    for(let i = 8; i < searchItem.length; i++) {
      searchItem[i].style.display = "none";
    }
    // もっと見るボタン
    moreBtn.addEventListener('click', (e) => {
      if(moreBtn.classList.contains('is-open')) {
        for(let i = 8; i < searchItem.length; i++) {
          searchItem[i].style.display = "none";
        }
        moreBtn.classList.remove('is-open');
        moreBtn.querySelector('span').innerHTML = "絞り込み条件をすべて表示";
      } else {
        for(let i = 8; i < searchItem.length; i++) {
          searchItem[i].style.display = "block";
        }
        moreBtn.classList.add('is-open');
        moreBtn.querySelector('span').innerHTML = "絞り込み条件を閉じる";
      }
    });
  } else if(searchItem.length && searchItem.length == 8 || searchItem.length && searchItem.length < 8) {
    if(moreBtn) {
      moreBtn.remove();
    }
  }

  // 結果リストの全選択ボタン処理
  let checkAllButton = document.getElementById('c-search-products__checkAll');
  const checkboxList = document.querySelectorAll('.c-search-products__resultTable tbody .c-search-products__checkbox input')
  if(checkAllButton) {
    checkAllButton.addEventListener('change', () => {
      let checkAllFlag = checkAllButton.checked ? true : false;
      if(checkboxList.length) {
        for(let i = 0; i < checkboxList.length; i++) {
          checkboxList[i].checked = checkAllFlag;
        }
      }
    });
    // 結果リストをひとつでもチェック外したら全選択チェックを外す処理
    if(checkboxList.length) {
      for(let i = 0; i < checkboxList.length; i++) {
        checkboxList[i].addEventListener('change', () => {
          if(!checkboxList[i].checked) {
            checkAllButton.checked = false; 
          }
        });
      }
    }
  }

  // ボタンによるスクロール移動
  const scrollRightButton = document.querySelector('.c-search-products__resultScroll--right');
  const scrollLeftButton = document.querySelector('.c-search-products__resultScroll--left');
  const scrollElem = document.querySelector('.c-search-products__resultTableWrap');
  if(scrollElem) {
    const scrollAmount = 150;
    scrollRightButton.addEventListener('click', () => {
        scrollElem.scrollLeft += scrollAmount;
    });
    scrollLeftButton.addEventListener('click', () => {
      scrollElem.scrollLeft -= scrollAmount;
    });
  }
  

  // 表示項目選択ボタンのモーダル
  const modalTrigger = document.querySelectorAll('.c-search-products .c-search-products__modalTrigger');
  if(modalTrigger.length) {
    for(let i = 0; i < modalTrigger.length; i++) {
      modalTrigger[i].addEventListener('click', (e) => {
        if(modalTrigger[i].querySelector('.c-search-products__modal')) {
          setContent(modalTrigger[i].querySelector('.c-search-products__modal').innerHTML);
        }
      });
    }

    // モーダル内用途別検索：すべて選択・解除の処理
    const onShow = () => {
      const modalShowAll = document.querySelectorAll('.c-search-products__modalShowAll');
      for(let i = 0; i < modalShowAll.length; i++) {
        modalShowAll[i].addEventListener('click',(e) => {
          const parentNode = e.target.closest('.c-search-products__modalInner');
          const checkboxList = parentNode.querySelectorAll('.c-search-products__checkbox input');
          for(let t = 0; t < checkboxList.length; t++) {
            checkboxList[t].checked = true;
          }
        });
      }
      const modalHideAll = document.querySelectorAll('.c-search-products__modalHideAll');
      for(let i = 0; i < modalHideAll.length; i++) {
        modalHideAll[i].addEventListener('click',(e) => {
          const parentNode = e.target.closest('.c-search-products__modalInner');
          const checkboxList = parentNode.querySelectorAll('.c-search-products__checkbox input');
          for(let t = 0; t < checkboxList.length; t++) {
            checkboxList[t].checked = false;
          }
        });
      }
    };

    MicroModal.init({
      onShow,
      disableScroll: true,
      awaitCloseAnimation: true,
      debugMode: true,
    });
  }

  
  
  
}
