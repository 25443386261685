import SlickSlider from '../common/SlickSlider';

export default class RecommendSlider extends SlickSlider {
  constructor(elem) {
    super();
    this.slideOption = {
      dots:          true,
      slidesToShow:   3,
      slidesToScroll: 3,
      prevArrow:     '<div class="slick-prev slick-arrow">',
      nextArrow:     '<div class="slick-next slick-arrow">',
      infinite: false,
      responsive: [{
        breakpoint: 748,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '47px',
          infinite: true,
        }
      }]
    };
    this.slide(elem);
  }
}
