export default () => {
  const headerElem = document.getElementById('header');
  const headerContainer = document.querySelector('.l-header__container');
  const options = {
    root: null,
    rootMargin: '-72px 0px 0px 0px',
    threshold: 0,
  }
  const onIntersect = (entries) => {
    if (!entries[0].isIntersecting) {
      headerContainer.classList.add('is-fixed');
    } else {
      headerContainer.classList.remove('is-fixed');
    }
  }
  const io = new IntersectionObserver(onIntersect, options);
  io.observe(headerElem);
}
