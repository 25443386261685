import { bp } from '../common/BreakPoint';
export default () => {
  const tabAcc = document.querySelectorAll('.c-tabAcc');
  const accBtns = document.querySelectorAll('.c-acc__btn');
  const tabBtns = document.querySelectorAll('.c-tab__btn');
  const tabConts = document.querySelectorAll('.c-tab__content');

  if(tabAcc.length) {
    const bpCheck = (bp) => {
      if (bp.matches) {
        for (let i = 0; i < tabBtns.length; i++) {
          accBtns[i].classList.remove('is-open');
          if(i === 0) {
            tabBtns[i].classList.add('is-active');
            tabConts[i].classList.add('is-open');
          } else {
            tabBtns[i].classList.remove('is-active');
            tabConts[i].classList.remove('is-open');
          }
        }
      } else {
        for (let i = 0; i < tabBtns.length; i++) {
          tabConts[i].classList.remove('is-open');
          tabBtns[i].classList.remove('is-active');
          if(i === 0) {
            accBtns[i].classList.add('is-open');
          }
        }
      }
    };
    bp.addEventListener("change", bpCheck);
    bpCheck(bp);
  }
}