import MicroModal from "micromodal";

const modalElem = document.getElementById("modal");
const triggerElem = document.getElementById("c-modal__trigger");
const contentElem = document.getElementById("c-modal__content");

if(modalElem) {
  const onClose = () => {
    contentElem.innerHTML = "";
  };

  MicroModal.init({
    onClose,
    disableScroll: true,
    awaitCloseAnimation: true,
    debugMode: true,
  });
}

export const setContent = (content) => {
  contentElem.innerHTML = content;
  triggerElem.click();
};
